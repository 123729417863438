import { template as template_101421411b0048e4b299c1e82339c412 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_101421411b0048e4b299c1e82339c412(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
