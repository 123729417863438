import { template as template_a9d49343efc74a09a5b06bbb4ff480f5 } from "@ember/template-compiler";
const FKLabel = template_a9d49343efc74a09a5b06bbb4ff480f5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
