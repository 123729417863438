import { template as template_0f6b6faa6d8443ec991960159c1143f0 } from "@ember/template-compiler";
const WelcomeHeader = template_0f6b6faa6d8443ec991960159c1143f0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
